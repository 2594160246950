import React from "react";

interface AgreementProps {
  recipientId: string;
  nickname: string;
  fio: string;
  inn: string;
  email: string;
  gateway: string;
}

function yookassa(
  nickname: string,
  recipientId: string,
  fio: string,
  inn: string,
  email: string,
) {
  return (
    <div>
      <h2>Оферта о договоре пожертвования</h2>
      <div>10 августа 2023, Санкт-Петербург</div>
      <ol>
        <li>
          Оферта адресована донорам – любым юридическим и физическим лицам.
        </li>
        <li>Предмет — договор пожертвования.</li>
        <li>Сумма пожертвования определяется донором в приложении к оферте.</li>
        <li>
          Назначение пожертвования – поддержка деятельности стримера {fio} (
          {nickname})
        </li>
        <li>
          Договор пожертвования заключается путем акцепта оферты донором. Оферта
          акцептуется перечислением {nickname} денежных средств с помощью
          платежной формы на сайте {recipientId}.oda.digital
        </li>
        <li>
          Датой акцепта оферты и датой заключения договора пожертвования
          является дата поступления денежных средств донора на расчетный счет
        </li>
        <li>
          Оферта вступает в силу со дня публикации на сайте {recipientId}
          .oda.digital. В оферту могут быть внесены изменения и дополнения,
          вступающие на следующий день с момента публикации на сайте. Оферта
          действует бессрочно.
        </li>
        <li>
          {nickname} не платит НДС поскольку применяется упрощенная система
          налогооблажения
        </li>
        <li>Стороны договора не подписывают акты</li>
        <div className="mt-4">
          <div>ФИО: {fio} </div>
          <div>ИНН: {inn} </div>
          <div>E-mail: {email}</div>
        </div>
      </ol>
    </div>
  );
}

function robokassa(
  fio: string,
  recipientId: string,
  inn: string,
  email: string,
) {
  return (
    <>
      <h1>Условия предоставления услуг</h1>
      <div style={{marginTop: "5px", marginBottom:"5px"}}>22 июня 2024</div>
      <ol>
        <h3>ОБЩИЕ ПОЛОЖЕНИЯ О ПУБЛИЧНОЙ ОФЕРТЕ</h3>
        <li>
          Настоящие правила являются публичной офертой в смысле, предусмотренном
          частью{" "}
          <a href="https://www.consultant.ru/document/cons_doc_LAW_5142/1a77b2ec302d6a384a228dff59e53680ccffaaca/">
            2 статьи 437 Гражданского кодекса Российской Федерации
          </a>{" "}
          (далее – ГК РФ).
        </li>
        <li>
          Акцептом (принятием) настоящей оферты, а также исполнением
          заключенного договора является перечисление денежных средств с
          использованием сервиса на счет стримера {fio} (далее – стример).
        </li>
        <li>
          Оферта вступает в силу со дня её публикации на сайте сервиса -
          https://{recipientId}.oda.digital (далее – сайт сервиса). В случае
          наличия копий настоящей оферты, её использования на других сайтах
          актуальным текстом оферты считается текст, размещенный на сайте
          сервиса. Текст настоящей оферты может быть изменен в любой момент
          путем публикации нового текста оферты на сайте сервиса. Для изменения
          текста настоящей оферты не требуется предварительное уведомление
          третьих лиц. С момента опубликования нового текста оферты, старые
          правила утрачивают свою юридическую силу.
        </li>
        <li>
          К любым отношениям, возникающим на основании данной оферты применяется
          Законодательство Российской Федерации. Положения настоящей оферты не
          могут противоречить законодательству Российской Федерации. Любые
          термины или определения, используемые в настоящей оферте, если иное
          прямо не указано, используются в тех значениях, в которых они
          используются в законодательстве Российской Федерации, в российской
          правовой доктрине и правоприменительной практике.
        </li>
        <li>
          Недействительность одного или нескольких положений настоящей оферты не
          отменяет всех остальных условий оферты.
        </li>
        <li>
          Текст данной оферты разделен на пункты. Заголовки используются
          исключительно для удобства восприятия текста оферты и не являются
          частью текста оферты.
        </li>
      <h3>ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h3>
        <li>
          Стример – сторона договора, {fio}; ИНН: {inn}; Email: {email}; Юр.
          адрес: предоставляется по запросу.
        </li>
        <li>
          Сервис – система, позволяющая пользователю отправить в дар денежные
          средства блогеру с возможностью добавить определенное сообщение для
          блогера
        </li>
        <li>
          Пользователь – сторона договора, физическое или юридическое лицо,
          обладающее достаточной правоспособностью и дееспособностью и
          использующее сервис
        </li>
        <li>
          Договор – соглашение, заключаемое между стримером и пользователем в
          момент использования сервиса и являющееся договором дарения по смыслу,
          предусмотренному{" "}
          <a href="https://www.consultant.ru/document/cons_doc_LAW_9027/e9b1f2aeabf3c6b71feaf2170dd49d4d1c70d5d5/">
            главой 32 ГК РФ
          </a>
          .
        </li>
      <h3>ОБЩИЕ ПОЛОЖЕНИЯ ДОГОВОРА</h3>
        <li>
          Согласно заключаемому между пользователем и блогером соглашением,
          пользователь безвозмездно передает стримеру в собственность денежные
          средства в размере, определяемом пользователем.
        </li>
        <li>
          Денежные средства по договору передаются безвозмездно. Передача
          денежных средств не обусловлена их использованием по определенному
          назначению.
        </li>
        <li>
          Моментом заключения договора, а также моментом исполнения обязательств
          по договору является момент списания денежных средств со счета
          пользователя.
        </li>
        <li>
          Местом совершения договора является город Москва, вне зависимости от
          места нахождения пользователя.
        </li>
        <li>
          Договор считается заключенным в письменной форме по смыслу{" "}
          <a href="https://www.consultant.ru/document/cons_doc_LAW_5142/08b8673b58e230c76f61b3a81736d4b2fd9ea3d2/">
            ч. 3 ст. 434 ГК РФ, ч. 3 ст. 438 ГК РФ
          </a>
          .
        </li>
      <h3>ПРАВА И ОБЯЗАННОСТИ СТОРОН</h3>
        <li>
          Заключая договор, пользователь подтверждает наличие достаточной
          правоспособности и дееспособности, а также наличие необходимого права
          на передаваемые стримеру денежные средства.
        </li>
        <li>
          Заключая договор, пользователь подтверждает отсутствие любых
          препятствий к заключению указанного договора, в том числе препятствий
          предусмотренных{" "}
          <a href="https://www.consultant.ru/document/cons_doc_LAW_9027/b1a993705399bf4cbb20df769e04d055c4d1f17a/">
            ст. 575
          </a>{" "}
          и{" "}
          <a href="https://www.consultant.ru/document/cons_doc_LAW_9027/d21ebba51af622880adb9551b9ca17da1d72762f/">
            ст. 576 ГК РФ
          </a>
          .
        </li>
        <li>
          Пользователь обязуется передать в собственность стримера денежные
          средства.
        </li>
        <li>
          Стример обязуется принять передаваемые пользователем денежные
          средства.
        </li>
        <li>
          Стример оставляет за собой право отказаться от дара без объяснения
          причин. Отказ считается совершенным в письменной форме в случае
          отправки пользователю соответствующего сообщения на адрес электронной
          почты (email).
        </li>
        <li>
          Стороны соглашаются, что отказ стримера от дара не наносит реального
          ущерба пользователю.
        </li>
        <h3>ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ</h3>
        <li>
          Стороны соглашаются, что для любых возникающих между ними споров обязательным является предварительный претензионный порядок.
        </li>
        <li>
          Стороны соглашаются, что любые споры между ними подсудны соответствующему суду по месту нахождения блогера.
        </li>
        <h3>ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h3>
        <li>Текст настоящей оферты опубликован 22.06.2024г.</li>
      </ol>
    </>
  );
}

export default function Agreement({
  recipientId,
  nickname,
  fio,
  inn,
  email,
  gateway,
}: AgreementProps) {
  return (
    <div className="h-100 d-flex align-items-center justify-content-center">
      <style
        dangerouslySetInnerHTML={{
          __html: `.card.rounded {max-width: 1200px!important;}`,
        }}
      />
      <div className="card shadow-lg rounded">
        <div className="card-header pb-4 pt-4 ps-4 align-middle">
          {gateway !== "robokassa" &&
            yookassa(nickname, recipientId, fio, inn, email)}
          {gateway === "robokassa" && robokassa(fio, recipientId, inn, email)}
          <h1>Условия использования сайта {recipientId}.oda.digital</h1>
          <ol>
            <li>
              {recipientId}.oda.digital использует YouTube API и Google
              Analytics. Используя и взаимодействуя с сайтом {recipientId}
              .oda.digital пользователь соглашается с условиями использования
              YouTube API Services (
              <a href="https://www.youtube.com/t/terms">
                https://www.youtube.com/t/terms
              </a>
              ) и Google Privacy Policy (
              <a href="https://policies.google.com/privacy?hl=ru">
                https://policies.google.com/privacy?hl=ru
              </a>
              ).
            </li>
            <li>
              Данные, полученные с помощью YouTube API (идентификатор контента,
              его содержимое, статистика и ограничения), используются для показа
              на стриме, проводимом {nickname}, и хранятся на сервере в
              обезличенном виде, т.е. без связи с конкретным пользователем.
            </li>
            <li>
              Сайт не собирает никаких данных пользователей, относящихся к
              YouTube, кроме поискового запроса, вводимого в явном виде в форму
              на сайте, и полученного с его помощью идентификатора контента.
            </li>
            <li>
              Сайт использует cookie для хранения прежде введеной пользователем
              информации (nickname в донате), что повышает удобство сайта для
              пользователя, и для работы сервиса Google Analytics (см Google
              Privacy Policy -{" "}
              <a href="https://policies.google.com/privacy?hl=ru">
                https://policies.google.com/privacy?hl=ru
              </a>
              ), что позволяет проводить аналитику для дальнейшего улучшения
              сервиса.
            </li>
          </ol>
          <div className="mt-4">
            <div>Контактные данные по техническим вопросам</div>
            <div>E-mail: stcarolas@gmail.com</div>
          </div>
        </div>
      </div>
    </div>
  );
}
