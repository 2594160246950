import React from "react";
import classes from "./ODALogo.module.css";

export default function ODALogo({}) {
  return (
    <>
      <div className={classes.logo}>
                <a href="https://oda.digital" className="footer-link" style={{ paddingRight: "1em"}}>

        <svg width="80" viewBox="0 0 276 156" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect y="0.828857" width="276" height="155" rx="16.5" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M152.996 121.829L206.996 30.8289C209.496 26.3289 211 25.8289 216.5 25.8289H245.996C248.996 25.8289 250.996 27.8289 250.996 30.8289V126.829C250.996 128.162 250.196 130.829 246.996 130.829H235.996C234.663 130.829 231.996 130.029 231.996 126.829V120.829C231.996 119.496 231.196 116.829 227.996 116.829H185.496C182.5 116.829 179.019 116.984 177.5 119.829C173.496 127.329 173.496 127.329 173.496 127.329C172.843 128.503 170.696 130.829 167.496 130.829H157.996C153 130.829 150.5 126.829 152.996 121.829ZM232 89.8289C232 93.0289 229.333 93.8289 228 93.8289H198.5C194.5 93.8289 194.667 89.8289 195.5 88.3289C203 76.1622 215.5 56.0289 217.5 52.8289C219.5 49.6289 222.333 48.8289 223.5 48.8289H228C230.8 48.8289 231.833 51.4955 232 52.8289V89.8289Z" fill="#161618"/>
<path fillRule="evenodd" clipRule="evenodd" d="M96 126.78C96 128.99 97.7883 130.78 99.9974 130.78L123.097 130.78C124.531 130.78 125.804 130.795 126.944 130.808C135.137 130.904 136.422 130.919 140.656 124.783C145.263 118.108 175.741 66.1586 188.605 44.2319C191.152 39.8921 193.008 36.7284 193.837 35.3246C196.118 31.1639 195.342 25.829 188.82 25.8289H137.144C131.611 25.8289 132.782 25.8289 133.966 25.8289C135.179 25.8289 136.406 25.8289 130.467 25.8289H100C97.7909 25.8289 96 27.6197 96 29.8289V126.78ZM115.064 103.774C115.064 105.978 116.847 107.767 119.052 107.774L123.246 107.787C123.29 107.787 123.332 107.788 123.375 107.789C123.401 107.79 123.426 107.79 123.453 107.791C124.026 107.799 126.868 107.727 128.367 105.6C131.479 101.185 148.535 73.547 151.364 68.8563C153.595 64.9871 156.979 58.8875 158.877 55.4664C159.145 54.9841 159.383 54.555 159.584 54.193C160.914 51.7984 160.141 49.8179 157.354 49.8179L138.264 49.8179C135.144 49.8179 135.804 49.8179 136.472 49.8179C137.156 49.8179 137.848 49.8179 134.499 49.8179H119.064C116.855 49.8179 115.064 51.6088 115.064 53.8179V103.774Z" fill="#161618"/>
<path fillRule="evenodd" clipRule="evenodd" d="M29 25.8289C26.7909 25.8289 25 27.6197 25 29.8289V126.829C25 129.038 26.7909 130.829 29 130.829H84C86.2091 130.829 88 129.038 88 126.829V29.8289C88 27.6197 86.2091 25.8289 84 25.8289H29ZM46 49.8289C43.7909 49.8289 42 51.6197 42 53.8289V103.829C42 106.038 43.7909 107.829 46 107.829H67C69.2091 107.829 71 106.038 71 103.829V53.8289C71 51.6197 69.2091 49.8289 67 49.8289H46Z" fill="#161618"/>
</svg>
        </a>
      </div>
    </>
  );
}
